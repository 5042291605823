<template>
  <div>
    <div class="alert alert-secondary px-1 py-75">
      <feather-icon
        icon="AlertCircleIcon"
        class="mr-50 mt-n25"
        size="16"
      />
      <span>This table might not show the latest data. Click "<b>Reload data</b>" for updates.</span>
    </div>

    <Table
      title-key="orderNumber"
      :actions="actions"
      :items="$store.getters['discrepancy/tableData']"
      :pagination="$store.state.discrepancy.tablePagination"
      :loading="$store.state.discrepancy.loadingTable"
      :fields="$store.getters['discrepancy/tableFields']"
      :keyword="$store.state.discrepancy.tableKeyword"
      :filter="$store.state.discrepancy.tableFilter"
      :sort="$store.state.discrepancy.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      search-placeholder="Search not available"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @load="loadData"
      @sort="sortData"
      @paginate="paginate"
      @search="search"
      @download="downloadData"
    />

    <!-- Match balance confirmation modal -->
    <b-modal
      v-model="matchBalanceModalVisible"
      title="Match balance"
      ok-title="Yes, sure"
      cancel-variant="white"
      @ok="matchBalance"
    >
      It will update the V1 balance to match the V2. Are you sure?.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      matchBalanceModalVisible: false,

      selectedData: '',

      // Tabs
      tabOptions: [
      ],

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: [
        'view',
        {
          type: 'other',
          children: [
            {
              label: 'Match balance',
              event: this.confirmMatchBalance,
            },
          ],
        },
        {
          type: 'custom',
          children: [
            {
              label: 'Reload data',
              icon: 'RefreshCcwIcon',
              event: this.hardReload,
            },
          ],
        },
      ],
    }
  },
  methods: {
    openPaymentGateway(row) {
      const paymentType = row.item.payment.type
      const paymentId = row.item.payment.transactionID

      if (['stripe', 'stripe_paynow', 'stripe_promptpay', 'stripe_google_play', 'stripe_applepay', 'stripe_wechat_pay'].includes(paymentType)) {
        // Stripe has the same URL format for both staging and live
        window.open(`https://dashboard.stripe.com/payments/${paymentId}`)
      } else if (paymentType === 'senangpay') {
        // SenangPay probably has the same URL format for both staging and live
        window.open(`https://app.senangpay.my/payment/receipt/${paymentId}`)
      } else if (paymentType === 'dragonpay_gcash') {
        // dragonpay doesnt have a transaction details page, all links are the same
        window.open('https://gw.dragonpay.ph/AdminWeb/Main.aspx')
      } else if (paymentType === 'xendit') {
        const trimmedPaymentId = paymentId.replace(/-/g, '')
        window.open(`https://dashboard.xendit.co/payment-links/single/${trimmedPaymentId}`)
      }
    },
    loadData() {
      return this.$store.dispatch('discrepancy/getTableData')
    },
    hardReload() {
      return this.$store.dispatch('discrepancy/getTableData', true)
    },
    loadDetail(id) {
      return this.$store.dispatch('discrepancy/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('discrepancy/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('discrepancy/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('discrepancy/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('discrepancy/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('discrepancy/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('discrepancy/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('discrepancy/SET_TABLE_KEYWORD', val)
    },
    downloadData() {
      return this.$store.dispatch('discrepancy/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
    confirmMatchBalance(data) {
      this.selectedData = data.item
      this.matchBalanceModalVisible = true
    },
    matchBalance() {
      this.$store.dispatch('discrepancy/match', this.selectedData.user_id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'User balance updated!',
              variant: 'success',
            },
          })

          this.matchBalanceModalVisible = false
          this.hardReload()
        })
    },
  },
}
</script>
